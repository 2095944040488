<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-bottom">
    <div class="navbar-text ml-auto">
      &copy; 2023
    </div>
  </nav>
</template>

<script>
/*
  The web view shows the HTML in the app page
 */
export default {
  name: "AppBottom"
}
</script>

<style scoped></style>
